import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function categoriesList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refCategoriesTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", label: "Nước sản xuất", sortable: false },
    {
      key: "created_at",
      label: "Ngày tạo",
      sortable: false,
    },
    { key: "action", label: "Hành động", sortable: false, class: "text-right" },
  ];
  const perPage = ref(10);
  const totalCategories = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50];
  const searchQuery = ref("");
  const isLoading = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refCategoriesTable.value
      ? refCategoriesTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCategories.value,
    };
  });

  const refetchData = () => {
    refCategoriesTable.value.refresh();
  };
  const fetchCategories = (ctx, callback) => {
    store
      .dispatch("phamarProduct/getCountries", {
        keyword: searchQuery.value,
        page_size: perPage.value,
        page_num: currentPage.value,
        sort_by: "id",
        order: "desc",
      })
      .then((response) => {
        const categories = response.data?.data || [];
        totalCategories.value = response.data?.page?.total;
        callback(categories);
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const createProductCategory = async (data) => {
    try {
      isLoading.value = true;
      return await store
        .dispatch("phamarProduct/createCountry", data)
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Thêm Nước sản xuất thành công",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });

          return true;
        });
    } catch (error) {
      console.log(error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Có lỗi xảy ra khi thêm đơn vị",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      return false;
    } finally {
      isLoading.value = false;
    }
  };

  const updateProductCategory = async (data) => {
    try {
      isLoading.value = true;
      return await store
        .dispatch("phamarProduct/updateCountry", {
          id: data.id,
          params: data,
        })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Cập nhật Nước sản xuất thành công",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });

          return true;
        });
    } catch (error) {
      console.log(error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Có lỗi xảy ra khi cập nhật Nước sản xuất",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      return false;
    } finally {
      isLoading.value = false;
    }
  };

  const deleteProductCategory = async (id) => {
    try {
      isLoading.value = true;
      return await store
        .dispatch("phamarProduct/deleteCountry", id)
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Xoá Nước sản xuất thành công",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          refetchData();
          return true;
        });
    } catch (error) {
      console.log(error);
      toast({
        component: ToastificationContent,
        props: {
          title: "Có lỗi xảy ra khi xoá Nước sản xuất",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      return false;
    } finally {
      isLoading.value = false;
    }
  };

  const timeout = ref(null);
  watch([currentPage, perPage, searchQuery], () => {
    showTable.value = true;
    clearTimeout(timeout.value);
    timeout.value = setTimeout(() => {
      refetchData();
      showTable.value = false;
    }, 1000);
  });
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchCategories,
    tableColumns,
    perPage,
    currentPage,
    totalCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    refCategoriesTable,
    isLoading,
    createProductCategory,
    updateProductCategory,
    deleteProductCategory,
    refetchData,
    // Extra
  };
}
