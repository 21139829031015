<template>
  <div>
    <CategoryAddNew
      :is-add-new-category-sidebar-active.sync="isAddNewCategorySideBar"
      :dataProp="dataCategory"
      :isLoading="isLoading"
      :isUpdate="isUpdate"
      @refetch-data="refreshTable"
      @onAdd="handleCreateProductCategory"
      @onUpdate="handleUpdateProductCategory"
    />
    <!-- <CategoryUpdate
      :is-update-category-sidebar-active.sync="isUpdateCategorySidebarActive"
      :dataProp="dataCategory"
      @refetch-data="refreshTable"
    />-->
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Hiển thị</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mục</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Tìm kiếm..."
              />
              <b-button variant="primary" @click="isAddNewCategorySideBar = true">
                <span class="text-nowrap">Thêm Nước sản xuất</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refCategoriesTable"
          class="position-relative"
          :items="fetchCategories"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Không có dữ liệu hiển thị"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                class="font-weight-bold d-block text-nowrap"
                @click="showEditBar(data.item)"
              >{{ data.item.title }}</b-link>
            </b-media>
          </template>

          <!-- Column: is Active -->
          <template #cell(status)="data">
            <span class="text-capitalize">
              <b-form-checkbox
                :checked="data.item.isActive"
                switch
                @change="switchActive(data.item)"
              />
            </span>
          </template>
          <!-- Column: createdAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">{{ formatDate(data.item.created_at) }}</span>
          </template>
          <!-- Column: updatedAt -->
          <template #cell(updatedAt)="data">
            <span class="text-capitalize">{{ formatDate(data.item.updatedAt) }}</span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <div class="d-flex justify-content-end">
              <feather-icon
                icon="EditIcon"
                size="22"
                class="text-primary mr-2"
                @click="showEditBar(data.item)"
              />
              <feather-icon
                icon="Trash2Icon"
                size="22"
                class="text-danger"
                @click="deleteRate(data.item.id)"
              />
            </div>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của {{ dataMeta.of }}
              mục
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BPagination,
  BOverlay,
  BFormCheckbox
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import categoryProduct from "@/views/pharmaProduct/Country/categoryProduct";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CategoryAddNew from "@/components/Country/CategoryAddNew.vue";

export default {
  name: "ProductCategory",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    CategoryAddNew,
    BFormCheckbox
  },
  watch: {
    isAddNewCategorySideBar(data) {
      if (!data) {
        this.isUpdate = false;
      }
    }
  },
  methods: {
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 2000);
    },
    switchActive(rate) {
      const payload = rate;
      // eslint-disable-next-line prefer-destructuring
      payload.isActive = !rate.isActive;
      store
        .dispatch("generalConfig/updateGeneralConfig", {
          id: rate.id,
          object: payload
        })
        .then(() => {
          this.refreshTable();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update active success",
              icon: "AlertTriangleIcon",
              variant: "success"
            }
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error update show",
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        });
    },
    showEditBar(data) {
      this.dataCategory = {
        title: data.title,
        name: data.name,
        key: data.key,
        status: {
          label: this.optionsActive?.find(e => e.value === data.isActive).label,
          value: data.isActive
        },
        id: data.id
      };
      this.isAddNewCategorySideBar = true;
      this.isUpdate = true;
    },
    formatDate(time) {
      return moment(time).format("HH:mm:ss DD/MM/YYYY");
    },
    deleteRate(id) {
      this.$bvModal
        .msgBoxConfirm("Bạn có chắc chắn muốn xoá Nước sản xuất này không?", {
          title: "Xoá Nước sản xuất",
          size: "sm",
          okVariant: "primary",
          okTitle: "Xác nhận",
          cancelTitle: "Huỷ",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteProductCategory(id);
          }
        });
    },
    async handleCreateProductCategory(data) {
      const isSuccess = await this.createProductCategory(data);
      if (isSuccess) {
        this.isAddNewCategorySideBar = false;
        this.refetchData();
      }
    },
    async handleUpdateProductCategory(data) {
      const isSuccess = await this.updateProductCategory(data);
      if (isSuccess) {
        this.isUpdate = false;
        this.isAddNewCategorySideBar = false;
        this.refetchData();
      }
    }
  },

  setup() {
    const dataCategory = ref({});
    const isAddNewCategorySideBar = ref(false);
    const isUpdateCategorySidebarActive = ref(false);
    const isUpdate = ref(false);
    const {
      timeout,
      showTable,
      fetchCategories,
      tableColumns,
      perPage,
      currentPage,
      totalCategories,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoriesTable,
      isLoading,
      refetchData,
      createProductCategory,
      updateProductCategory,
      deleteProductCategory
    } = categoryProduct();
    return {
      timeout,
      showTable,
      // Sidebar
      dataCategory,
      isUpdateCategorySidebarActive,
      isAddNewCategorySideBar,
      fetchCategories,
      tableColumns,
      perPage,
      currentPage,
      totalCategories,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoriesTable,
      isLoading,
      isUpdate,
      refetchData,
      createProductCategory,
      updateProductCategory,
      deleteProductCategory,

      // Filter
      avatarText

      // Extra Filters
    };
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
